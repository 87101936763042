import bootstrap from "bootstrap";
import LazyLoadImages from "./helpers/lazyload";

$(document).ready(function () {
    // Мобильное меню
   const menuBtn = $('.js-mobile-menu');
   const menu = $('.mobile-menu');

    menuBtn.on('click', function () {
        menu.slideToggle()
    });

    // Инициализация Lazy Load
    new LazyLoadImages('.lazy');

    $('.gallery').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            gallery: {
                enabled:true
            }
        });
    });
});

